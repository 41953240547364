<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin::Notifications-->
    <div class="dropdown" v-if="messageCount > 0">
      <!--begin::Toggle-->
        <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px">
          <router-link to="/">
          <div class="btn btn-icon btn-dropdown btn-lg mr-1 pulse pulse-white"
            v-b-popover.hover.bottomleft="messageCountString">
            <span class="svg-icon svg-icon-xl svg-icon-white">
              <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg-->
              <span class="navi-icon">
                <i class="fa fa-envelope text-white"></i>
              </span>
              <!--end::Svg Icon-->
            </span>
            <span class="pulse-ring"></span>
          </div>
        </router-link>
        </div>
      <!--end::Toggle-->
    </div>
    <!--end::Notifications-->

  </div>
  <!-- end:: Header Topbar -->
</template>

<style>
.nav-tabs {
  display: none !important;
}
</style>

<script>
import i18nService from "@/core/services/i18n.service.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
      messageCount: 0,
      coachlistid: "",
    };
  },
  async mounted() {
    this.$store.dispatch("getCoachListInfo")
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    }
  },
  computed: {
    ...mapGetters(["coachMessageCount", "coachListInfo"]),
    messageCountString() {
      var countString = "You have " + this.messageCount + " unread message"
      if (countString > 1) countString = countString + "s"
      return countString
    }
  },
  watch: {
    coachMessageCount(newValue) {
      this.messageCount = newValue
    },
    coachListInfo(newValue) {
      if (this.coachlistid == "") {
        let coachid = newValue[0].id
        this.coachlistid = coachid
        this.$store.dispatch("getCoachMessagesCount", { coachid: coachid })
      }
    }
  }
};
</script>
