<template>
  <div id="kt_footer" class="footer bg-white py-4 d-flex flex-lg-column">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-dark font-weight-bold mr-2">© {{currentDate.getFullYear()}}</span>
        <a
          href="http://ingomu.com"
          target="_blank"
          class="text-dark text-hover-primary"
          >Ingomu Learning, LLC</a>
      </div>
      <!--end::Copyright-->
    </div>
    <!--end::Container-->
    <cookie-law theme="dark-lime"></cookie-law>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CookieLaw from 'vue-cookie-law'

export default {
  name: "KTFooter",
  components: { CookieLaw },
  data() {
    return {
        currentDate: new Date
    }
  },
 computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
